<template>
<ion-page>
    <syaberu-menu/>
    <ion-content :fullscreen="true">
           <ion-list v-if="isEmpty">
            <ion-item>
              <h2>新着のおしらせはありません</h2>
            </ion-item>
          </ion-list>
          <!-- loopの単位はここ -->
          <ion-list v-else>
              <ion-item @click="detail(notification)" v-for="notification in notifications" :key="notification.lastUpdateTimestamp">
                <ion-chip slot="start" v-if="notification.priority == 0">
                  <ion-icon v-if="notification.priority == 0" :icon="informationCircleOutline"></ion-icon>
                  <ion-label>通常</ion-label>
                </ion-chip>
                <ion-chip slot="start" color="danger" v-else>
                  <ion-icon :icon="alertCircleOutline"></ion-icon>
                  <ion-label>必須</ion-label>
                </ion-chip>
                <ion-label>
                 <!-- 添付がありますよー -->
                  <h2>{{notification.title}}</h2>
                  <p>
                     {{notification.displayDate}}
                     <ion-icon v-if="notification.imageURL != null" :icon="documentAttachOutline"/>
                  </p>
                </ion-label>
              </ion-item>
          </ion-list>
    </ion-content>
</ion-page>
</template>

<script>
import { IonPage,
  IonContent,
  IonLabel,
  IonIcon,
  IonChip,
  modalController,
  IonList,
  IonItem } from '@ionic/vue';
import  NotificationModal from './NotificationModal.vue'
import  SyaberuMenu from './SyaberuMenu.vue';
import  getNotifications from "./helper/getNotifications.js";
//読み込むアイコンを決めないといけない
import { 
  alertCircleOutline,
  informationCircleOutline,
  documentAttachOutline } from 'ionicons/icons';
export default  {
  name: 'Notification',
  components: {
    IonPage, 
    IonContent,
    IonLabel,
    IonIcon,
    IonChip,
    SyaberuMenu,
    IonList,
    IonItem },
  data(){
    return {
      "notifications" : null
    }
  },
  setup() {
    return {
      alertCircleOutline,
      informationCircleOutline,
      documentAttachOutline
    }
  },
  async mounted(){
      this.$root.startLoading();
      const user = this.$store.state.activeUser;
      this.notifications = await getNotifications(this.axios,user.tenantId,user.userId,user.guardianId,false);
      this.$root.endLoading();
  },
  computed : {
    isEmpty(){
      return this.notifications == null || this.notifications.length == 0
    }
  },
  methods : {
    async detail(notification) {
      const modal = await modalController
        .create({
          component: NotificationModal,
          componentProps: {
            title: notification.title, //these values are overriden,
            message : notification.message,
            priority : notification.priority,
            originalDocId : notification.originalDocId,
            tenantId : this.$store.state.activeUser.tenantId,
            guardianId: this.$store.state.activeUser.guardianId,
            userId : this.$store.state.activeUser.userId,
            userName : this.$store.state.activeUser.userName,
            YYYYMMDD : notification.YYYYMMDD,
            docId : notification.docId,
            range : notification.range,
            targetUserIds : notification.targetUserIds,
            targetClass : notification.targetClass,
            isRead : notification.read,
            imageURL : notification.imageURL,
            isRef : false,
            createdAt : notification.createdAt
          },
        })
      this.$store.commit('setModal',modal);
      return modal.present();
    }
  }
}
</script>
<style scoped>
</style>
